import { resolveId } from '@wix/communities-blog-client-common';
import { fetchPostMetadata } from '../../common/actions/fetch-post-metadata';
import {
  getIsRecentPostsEnabled,
  getIsRelatedPostsEnabled,
} from '../../common/selectors/app-settings-selectors';
import { fetchProfileUrls } from '../../common/store/profile-urls/profile-urls-actions';
import type { AppStore, NormalizedPost } from '../../common/types';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import fetchRelatedPosts from '../actions/fetch-related-posts';

interface FetchRecentPostsAndCommentsParams {
  store: AppStore;
  post: NormalizedPost;
  isPostMetadataFromAdapterEnabled: boolean;
}

export async function fetchRecentPostsAndComments({
  store,
  post,
  isPostMetadataFromAdapterEnabled,
}: FetchRecentPostsAndCommentsParams) {
  const state = store.getState();
  const parallelRequests: Promise<unknown>[] = [];
  const relatedPostsEnabled = getIsRelatedPostsEnabled(state) !== false;
  const recentPostsEnabled = getIsRecentPostsEnabled(state);
  const hasRelatedPosts = post.relatedPostIds && post.relatedPostIds.length > 0;

  if (!isPostMetadataFromAdapterEnabled && !post.isDemo) {
    parallelRequests.push(store.dispatch(fetchPostMetadata(resolveId(post))));
  }

  parallelRequests.push(store.dispatch(fetchProfileUrls()));

  if (recentPostsEnabled || (!hasRelatedPosts && relatedPostsEnabled)) {
    parallelRequests.push(store.dispatch(fetchRecentPosts(post)));
  }

  if (relatedPostsEnabled && hasRelatedPosts) {
    parallelRequests.push(store.dispatch(fetchRelatedPosts(post)));
  }

  await Promise.all(parallelRequests);
}
