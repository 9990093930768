import { get } from 'lodash';
import type {
  ControllerFlowAPI,
  IControllerConfig,
} from '@wix/yoshi-flow-editor';
import {
  getCurrentUser,
  isBlocked,
  EXPERIMENTS,
} from '@wix/communities-blog-client-common';
import { AppData } from '../../../viewer.app';
import { fetchPost } from '../../common/actions/fetch-post';
import { pageOpened } from '../../common/actions/page-opened';
import { createNotFoundPageRouter } from '../../common/controller/create-router';
import { Router } from '../../common/router';
import createPermissionChecker from '../../common/services/create-permission-checker';
import { createPermissionsChecker } from '../../common/services/create-permission-helpers';
import {
  POST_EDIT_PAGE,
  POST_PAGE_NOT_FOUND,
} from '../../common/services/detect-route';
import { resolvePostSlug } from '../../common/services/slug';
import { isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { setIsPostInPreview } from '../../common/store/is-post-in-preview/set-is-post-in-preview-action';
import {
  AppState,
  AppStore,
  NormalizedPost,
  RedirectFn,
  RouteResolverFn,
  WixCodeApi,
} from '../../common/types';
import {
  ROUTE_404,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_CREATE_POST,
  ROUTE_EDIT_COMMENT,
  ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
  ROUTE_EDIT_POST,
  ROUTE_LAST_POST,
  ROUTE_LOGIN,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_PREVIEW_POST,
} from '../constants/routes';
import { fetchInitialPostEditorData } from '../services/post-editor';
import { customRouteHandler } from './route-handlers/custom-route-handler';
import { createLastPostPageRouter } from './route-handlers/last-post-page-router-handler';
import { createPostPageRouter } from './route-handlers/post-page-router-handler';

const assertUserLoggedInAndNotBlocked = (
  state: AppState,
  redirect: RedirectFn,
) => {
  const currentUser = getCurrentUser(state);
  if (currentUser) {
    if (isBlocked(state)) {
      return {
        isUserValid: false,
        redirectState: redirect(ROUTE_ACCOUNT_SUSPENDED),
      };
    }
  } else {
    return { isUserValid: false, redirectState: redirect(ROUTE_LOGIN) };
  }
  return { isUserValid: true };
};

const canPreviewPost = (state: AppState, post: NormalizedPost) => {
  const user = getCurrentUser(state);
  const perm = createPermissionChecker(state, user);
  const can = createPermissionsChecker(perm);
  return can('preview', 'post', post);
};

const createPostPreviewPageRouter =
  (store: AppStore): RouteResolverFn<{ postId: string; instance: string }> =>
  async ({ params }, redirect) => {
    const state = store.getState();

    if (!get(state, 'auth.isAuthenticated')) {
      return redirect(ROUTE_404);
    }

    const postId = params.postId;

    const additionalParams = {
      includeDraft: true,
      instance: params.instance,
    };

    return store
      .dispatch(fetchPost(postId, additionalParams))
      .then((post) =>
        canPreviewPost(store.getState(), post)
          ? store.dispatch(setIsPostInPreview(true))
          : redirect(ROUTE_404),
      )
      .catch((error) => {
        if (error.status === 401) {
          return redirect(`/login?redirect=/${postId}`);
        }
        if (error.status === 404) {
          return redirect(ROUTE_404);
        }
        throw error;
      });
  };

const createPostEditPageRouter =
  (store: AppStore): RouteResolverFn =>
  async ({ params }, redirect) => {
    const state = store.getState();

    const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(
      state,
      redirect,
    );
    if (!isUserValid) {
      return redirectState;
    }

    const postSlug = resolvePostSlug(params);
    return fetchInitialPostEditorData(state, store.dispatch, postSlug).then(
      () =>
        !isSSR(state) && store.dispatch(pageOpened({ page: POST_EDIT_PAGE })),
    );
  };

const createPostCreatePageRouter =
  (store: AppStore): RouteResolverFn =>
  async (_route, redirect) => {
    const state = store.getState();

    const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(
      state,
      redirect,
    );
    if (!isUserValid) {
      return redirectState;
    }

    const can = createPermissionsChecker(
      createPermissionChecker(state, getCurrentUser(state)),
    );
    if (!can('create', 'post')) {
      return redirect(ROUTE_404);
    }

    return fetchInitialPostEditorData(state, store.dispatch).then(
      () =>
        !isSSR(state) && store.dispatch(pageOpened({ page: POST_EDIT_PAGE })),
    );
  };

export const createRouter = (
  store: AppStore,
  _config: IControllerConfig,
  wixCodeApi: WixCodeApi,
  isCustomUrlEnabled: boolean,
  appData: AppData,
  flowAPI: ControllerFlowAPI,
) => {
  const router = new Router();
  const isClassicEditor = flowAPI.environment.isClassicEditor;
  const isClassicEditorAndExperimentDisabled =
    isClassicEditor &&
    !flowAPI.experiments.enabled(EXPERIMENTS.FETCH_AND_CACHE_TPA_INNER_ROUTES);

  if (isClassicEditorAndExperimentDisabled || !isClassicEditor) {
    router.add(ROUTE_LAST_POST, createLastPostPageRouter({ store }));
  }

  router.add(
    ROUTE_404,
    createNotFoundPageRouter(store, wixCodeApi, POST_PAGE_NOT_FOUND, ROUTE_404),
  );
  router.add(ROUTE_LOGIN);
  router.add(ROUTE_CREATE_POST, createPostCreatePageRouter(store));
  router.add(ROUTE_EDIT_POST, createPostEditPageRouter(store));
  router.add(ROUTE_PREVIEW_POST, createPostPreviewPageRouter(store));
  router.add(ROUTE_EDIT_COMMENT);

  for (const route of [ROUTE_POST, ROUTE_POST_ADVANCED_SLUG]) {
    router.add(route, createPostPageRouter({ store, wixCodeApi, appData }), {
      preFetch: true,
    });
  }

  router.add(ROUTE_EDIT_COMMENT_ADVANCED_SLUG);
  isCustomUrlEnabled &&
    router.addCustomRouteHandler(() => customRouteHandler(wixCodeApi));
  router.fallback(ROUTE_404);
  return router;
};
