import {
  EXPERIMENTS,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import {
  isEditor,
  isPreview,
  isSSR,
} from '../../../common/store/basic-params/basic-params-selectors';
import { AppStore, RouteResolverFn } from '../../../common/types';
import { fetchLastPost } from '../../actions/fetch-last-post';
import { biOpenPostPageInEditor } from '../../actions/open-post-page-editor';
import { ROUTE_404 } from '../../constants/routes';
import { fetchRecentPostsAndComments } from '../fetch-data-for-post-widgets';

interface CreateLastPostPageRouterParams {
  store: AppStore;
}

export const createLastPostPageRouter =
  ({ store }: CreateLastPostPageRouterParams): RouteResolverFn =>
  async (_route, redirect) => {
    const state = store.getState();
    const isPostMetadataFromAdapterEnabled = isExperimentEnabled(
      state,
      EXPERIMENTS.POST_METADATA_FROM_ADAPTER,
    );

    if (!(isEditor(state) || isPreview(state))) {
      return redirect(ROUTE_404);
    }

    if (!isSSR(state)) {
      store.dispatch(biOpenPostPageInEditor());
    }

    const lastPost = await store.dispatch(fetchLastPost());

    if (lastPost) {
      await fetchRecentPostsAndComments({
        store,
        post: lastPost,
        isPostMetadataFromAdapterEnabled,
      });
    }
  };
