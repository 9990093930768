import { IWixAPI, ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { BLOG_POST_PAGE_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { PostPageRenderModel } from '../external/blocks-widget/post-page-render-model';
import { getSectionPathname } from '../external/common/controller/helpers';
import { Router } from '../external/common/router';
import { initI18N } from '../external/common/services/controller-translate';
import { resolvePostSlug } from '../external/common/services/slug';
import {
  ROUTE_404,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_PREVIEW_POST,
} from '../external/post-page/constants/routes';
import { customRouteHandler } from '../external/post-page/controller/route-handlers/custom-route-handler';
import { getSectionUrl } from '../external/post-page/services/get-section-url';
import { fetchMetadata } from './metadata';
import { getPostAndCategories } from './post';

interface FetchInitialDataParams {
  wixCodeApi: IWixAPI;
  instanceId: string;
  flowAPI: ViewerScriptFlowAPI;
}

export const fetchInitialData = async ({
  wixCodeApi,
  instanceId,
  flowAPI,
}: FetchInitialDataParams): Promise<PostPageRenderModel> => {
  const readingSessionId = `${parseInt(
    `${Math.random() * 10000000}`,
    10,
  )}-${Date.now()}`;
  const { postId, instance } = await extractPostIdAndInstanceFromCurrentUrl(
    wixCodeApi,
    instanceId,
  );

  let initialData: Awaited<ReturnType<typeof getInitialData>>;
  const warmupDataKey = `PostPageWarmupData-${postId}`;

  const warmupDataGet = () => {
    try {
      const value = JSON.parse(wixCodeApi.window.warmupData.get(warmupDataKey));
      return value;
    } catch {
      return undefined;
    }
  };

  if (
    flowAPI.essentials.experiments.enabled(
      'specs.wixBlog.UseWarmupStateInNewPostPage',
    ) &&
    flowAPI.environment.isSSR
  ) {
    initialData = await getInitialData({
      wixCodeApi,
      instanceId,
      flowAPI,
      postId,
      instance,
    });
    wixCodeApi.window.warmupData.set(
      warmupDataKey,
      JSON.stringify(initialData),
    );
  } else {
    initialData =
      warmupDataGet() ??
      (await getInitialData({
        wixCodeApi,
        instanceId,
        flowAPI,
        postId,
        instance,
      }));
  }

  initI18N(initialData.translations);

  return new PostPageRenderModel({
    post: initialData.post,
    tags: initialData.post.tags ?? [],
    metadata: {
      ...initialData.metadata,
      isLiked: Boolean(initialData.post.isLiked),
    },
    readingSessionId,
    categories: initialData.categories,
    appData: initialData.appData,
    instanceId,
    wixCodeApi,
  });
};

interface GetInitialDataParams {
  wixCodeApi: IWixAPI;
  instanceId: string;
  flowAPI: ViewerScriptFlowAPI;
  postId: string;
  instance: string;
}

const getInitialData = async ({
  wixCodeApi,
  instanceId,
  flowAPI,
  postId,
  instance,
}: GetInitialDataParams) => {
  const { post, categories, appData, translations } =
    await getPostAndCategories({
      wixCodeApi,
      flowAPI,
      postId,
      instance,
    });

  const metadata = await fetchMetadata({
    wixCodeApi,
    flowAPI,
    postSlug: post.slug,
    postId: post.id,
  });

  return {
    post,
    categories,
    appData,
    translations,
    metadata,
  };
};

const extractPostIdAndInstanceFromCurrentUrl = async (
  wixCodeApi: IWixAPI,
  instanceId: string,
) => {
  const router = new Router();
  const pathname = getSectionPathname({
    wixCodeApi,
    sectionUrl: await getSectionUrl({
      wixCodeApi,
      instanceId,
      sectionId: BLOG_POST_PAGE_SECTION_ID,
    }),
  });

  router.add(ROUTE_PREVIEW_POST);
  router.add(ROUTE_POST);
  router.add(ROUTE_POST_ADVANCED_SLUG);
  router.addCustomRouteHandler(() => customRouteHandler(wixCodeApi));
  router.fallback(ROUTE_404);

  const matchPathResult = await router.matchPath({ pathname });

  if (
    'postId' in matchPathResult.params &&
    'instance' in matchPathResult.params
  ) {
    return matchPathResult.params;
  }

  const postId = resolvePostSlug(matchPathResult.params);

  return {
    postId,
    instance: undefined,
  };
};
